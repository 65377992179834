import Ripples from "react-ripples";
export default function CustomSmpButton({
  className,
  type,
  func,
  icon,
  text,
  disabled,
  width,
  height,
  fontSize,
  color,
  backgroundColor,
  padding,
  border,
  borderRadius,
  margin,
  background,
  minHeight,
  alignItems,
  position,
  cursor,
  fontWeight,
  transform,
  textTransform,
  zIndex,
  left,
}) {
  return (
    <Ripples>
      <button
        style={{
          left,
          zIndex,
          fontWeight,
          cursor,
          position,
          alignItems,
          color,
          backgroundColor,
          fontSize,
          width,
          height,
          padding,
          border,
          borderRadius,
          margin,
          background,
          minHeight,
          transform,
          textTransform,
        }}
        className={className}
        disabled={disabled}
        onClick={func}
        type={type}
      >
        {text}
        {icon}
      </button>
    </Ripples>
  );
}
